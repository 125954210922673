import { memo } from "react"
import { useInView } from "react-intersection-observer"

const InputSelect = ({
  id,
  className,
  title = "title",
  value,
  onChange,
  options = [],
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
  })

  // if (title === "First use") { console.log("First use: ", value)}

  return (
    <div id={id} ref={ref} data-inview={inView} className={`${className}`}>
      <h6 className="text-sm text-primary font-bold mb-2">{title}</h6>

      <select
        value={value}
        onChange={(e) => {
          onChange
            ? onChange(e.target.value)
            : console.log("on Change Tidak Terdeteksi")
        }}
        className={`h-9 p-1.5 appearance-none none border rounded-lg ${className}`}
      >
        <option>
          Select
        </option>
        {options.map((value, index) => {
          return <option key={index.toString()}>{value.name}</option>
        })}
      </select>
    </div>
  )
}

export default memo(InputSelect)
