import React, { useState } from "react"
import KaerWater from "../../assets/img/kaerWater.png"
import KaerTermo from "../../assets/img/kaerThermo.png"
import Check from "../../assets/img/check.png"

export const ProductButton = () => {
  const [Active, setActive] = useState(0)

  return (
    <div className="flex flex-initial w-full justify-between space-x-4">
      <div
        className={`
        ${
          Active === 0
            ? "border-[#00A6EF] bg-white"
            : "border-[#D6D6D6] bg-[#F6F6F6]"
        } 
          border-[2px] 
          rounded-lg 
          h-20 w-full
          flex justify-center items-center 
          relative
        `}
      >
        <div
          className="
          flex items-center justify-center 
          text-center 
          space-x-2 h-full px-4
        "
        >
          <img src={KaerWater} alt="" />
          <span className="text-[#00A6EF] text-lg font-bold ">Kaer Water</span>
        </div>
        <img src={Check} className="absolute right-[-2px] top-[-2px]" alt="" />
      </div>

      <div
        className={`
        ${Active === 1 ? "border-[#829197] " : "border-[#D6D6D6] bg-[#F6F6F6]"} 
          border-[2px] 
          rounded-lg 
          h-20 w-full
          flex justify-center items-center
        `}
      >
        <div
          className="
          flex items-center justify-center space-x-2 
          text-center 
          h-full
        "
        >
          <img src={KaerTermo} alt="" />
          <span className="text-[#8A8A8A] text-lg font-bold ">Kaer Air</span>
          <div className="border-2 px-2 border-[#D6D6D6] text-xs font-semibold text-[#8A8A8A] rounded-lg">
            Coming soon
          </div>
        </div>
      </div>
    </div>
  )
}
