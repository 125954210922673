import { TimePicker } from "antd";
import { useState } from "react";
import * as dayjs from 'dayjs';

export const InputTimePicker = ({ value, format, placeholder, handleChange}) => {
    // const [_value, setValue] = useState(dayjs());
    // console.log("InputTimePicker value:", value);
    return (
        <TimePicker 
          value={value} 
          format={format} 
          placeholder={placeholder}
          minuteStep={15}
          needConfirm={false}
          showNow={false}
          onChange={(time, timeString) => {
            console.log("TimePicker on change: ", time, timeString);
            handleChange(time);
          }}
        //   onSelect={(time, timeString) => {
        //     console.log("TimePicker on select: ", time, timeString);
        //     handleChange(time);
        //   }}
        />
    )
}