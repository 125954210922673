import { Switch } from "antd";
import { InputTimePicker } from "./InputTimePicker";

export const InputTimeRangePicker = ({
  title = "title",
  onCheckedChange,
  isChecked,
  onTimeChange,
  className,
  value,
  isNightLoad,
}) => {
  
  const format = 'HH:mm';
  // console.log("InputTimeRangePicker - title:", title, ", value:", value);
  
  return (
    <div className={`my-4 font-bold ${className}`}>
      <div className="flex flex-row justify-start items-center gap-3">
          {/* <label className="text-sm text-primary font-normal">OFF</label> */}
          <div className="grow text-base text-primary font-bold ">{title}</div>
          <Switch 
            className="w-18"
            onChange={onCheckedChange} 
            checked={!isChecked} 
            checkedChildren="Open" 
            unCheckedChildren="Closed" 
          />
          <div className={`flex flex-row justify-between gap-3 ${isChecked && "hide-me"}`}>
            <div className="leading-7" style={{ color: "#005DAA"}}>From</div>
            <InputTimePicker 
              value={value && value.length > 0 ? value[0] : ""} 
              format={format} 
              placeholder={""}
              handleChange={(time) => onTimeChange(time, "start")}
            />  
            <div className="leading-7" style={{ color: "#005DAA"}}>To</div>
            <InputTimePicker 
              value={value && value.length > 1 ? value[1] : ""} 
              format={format} 
              placeholder={""}
              handleChange={(time) => onTimeChange(time, "end")}
            />
          </div>
      </div>
    </div>
  )
}
