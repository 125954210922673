import { memo } from "react"
import { useInView } from "react-intersection-observer"

const InputDefault = ({ id, value, className, title = "title", onChange, prepend = null, formatWithComma = false }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
  })

  const showNumberWithCommas = (x) => {
    return x.toString().replaceAll(",", "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let _value = "";

  if (formatWithComma) {
    _value = showNumberWithCommas(value);
  } else {
    _value = value;
  }
  
  return (
    <div id={id} ref={ref} data-inview={inView} className={`${className}`}>
      <h6 className="text-sm text-primary font-bold mb-2">{title}</h6>
        {prepend && <div className="pointer-events-none absolute bottom-2 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">{prepend}</span>
        </div>}
      <input
        value={_value}
        onChange={(e) => {
          onChange
            ? onChange(e.target.value)
            : console.log("on Change Tidak Terdeteksi")
        }}
        className={`h-9 p-3 border rounded-lg ${className} ${prepend && "pl-7"}`}
        type="text"
      />
    </div>
  )
}

export default memo(InputDefault)
