import moment from "moment"
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from "dayjs/plugin/timezone";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const baseUrl = "http://127.0.0.1:8000"

export const numberOnly = (text) => {
  // return text.replace(/[^0-9]/g, "")
  let sanitizedInput = text.replace(/[^0-9.]/g, "")

  // Ensure there is only one decimal point
  sanitizedInput = sanitizedInput.replace(/(\..*)\./g, "$1")

  // If the text starts with a decimal point, add a leading 0
  sanitizedInput = sanitizedInput.replace(/^\./, "0.")

  return sanitizedInput
}

// old function
export const renderJam = (props) => {
  function convertToTwoDigit(number) {
    return number < 10 ? "0" + number : "" + number
  }

  if (props !== 0) {
    let data = props / 60
    let dataSplit = []
    if (data.toString().includes(".")) {
      dataSplit = data.toString().split(".")
    } else {
      dataSplit = [data.toString()]
    }
    if (dataSplit[0] <= 9 && dataSplit[1] === undefined) {
      return `${convertToTwoDigit(dataSplit[0])}:00`
    } else if (dataSplit[0] > 9 && dataSplit[1] === undefined) {
      return `${convertToTwoDigit(dataSplit[0])}:00`
    } else {
      if (dataSplit[1] === undefined) {
        return `${convertToTwoDigit(dataSplit[0])}:00`
      } else {
        return `${convertToTwoDigit(dataSplit[0])}:30`
      }
    }
  } else {
    return "00.00"
  }
}

// new function
export const convertSliderValueToTime = (value, extraSecond = false) => {
  const totalMinutes = value * 30
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  // Format the time in 24-hour format
  const formattedHours = hours < 10 ? `0${hours}` : hours
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  // console.log(formattedHours, "formattedHours", formattedMinutes)

  const formattedTime = `${
    formattedHours === 24
      ? (formattedHours - 1).toString().padStart(2, "0")
      : formattedHours > 23
      ? (formattedHours - 24).toString().padStart(2, "0")
      : formattedHours
  }:${formattedHours === 24 ? "59" : formattedMinutes}${
    extraSecond ? ":00" : ""
  }${formattedHours > 24 ? " (+1D)" : ""}`

  return formattedTime
}

export const initialTimeValue = `${moment(new Date()).format(
  "YYYY-MM-DD "
)}00:09:00 - ${moment(new Date()).format("YYYY-MM-DD ")}21:00:00`
export const initialTimeValueNight = `${moment(new Date()).format(
  "YYYY-MM-DD "
)}21:00:00 - ${moment(new Date()).format("YYYY-MM-DD ")}09:00:00`

// export const defaultDayLoad = [10, 42]
// const start = dayjs().hour(5).minute(0).second(0); // moment().set({'hour': 5, 'minute': 0, 'second': 0});
// const end = dayjs().hour(21).minute(0).second(0); // moment().set({'hour': 21, 'minute': 0, 'second': 0});
export const hotelOperationHours = [
  dayjs().hour(0).minute(0).second(0),
  dayjs().hour(23).minute(59).second(0),
];
export const officeOperationHours = [
  dayjs().hour(8).minute(0).second(0), 
  dayjs().hour(18).minute(0).second(0)
];
export const retailOperationHours = [
  dayjs().hour(10).minute(0).second(0), 
  dayjs().hour(22).minute(0).second(0)
];
export const educationOperationHours = [
  dayjs().hour(7).minute(0).second(0), 
  dayjs().hour(22).minute(0).second(0)
];
export const defaultDayLoad = [
  dayjs().hour(5).minute(0).second(0), 
  dayjs().hour(21).minute(0).second(0)
];
export const defaultNightLoad = [42, 66]

export const getOperationHours = (building_type) => {
  if (!building_type) {
    return [...defaultDayLoad];
  }
  else if (building_type.toLowerCase() === 'office') {
    return [...officeOperationHours];
  }
  else if (building_type.toLowerCase() === 'retail') {
    return [...retailOperationHours];
  }
  else if (building_type.toLowerCase() === 'institutional/education') {
    return [...educationOperationHours];
  }
  else if (building_type.toLowerCase() === 'hotel') {
    return [...hotelOperationHours];
  }
  return [...defaultDayLoad];
}

export const initialBuildingState = {
  building_location: "",
  address: "",
  building_type: "",
  first_mix_type: "",
  first_gfa: "",
  second_mix_type: "",
  second_gfa: 0,
  electricity_tariff: "",
  water_tariff: "",
  operation_hour_monday_friday_1: [...defaultDayLoad],
  operation_hour_saturday_1: "",
  operation_hour_sun_ph_1: "",
  night_load_monday_friday_1: "",
  night_load_saturday_1: "",
  night_load_sun_ph_1: "",
  night_load_area_1: "Not sure",
  operation_hour_monday_friday_2: "",
  operation_hour_saturday_2: "",
  operation_hour_sun_ph_2: "",
  night_load_monday_friday_2: "",
  night_load_saturday_2: "",
  night_load_sun_ph_2: "",
  night_load_area_2: "Not sure",
}

export const dayjsDateToString = value => {
  console.log(`dayjsDateToString: ${value}, ${typeof value}`, value);
  if (typeof value === "string" || value === undefined) {
    return "";
  } else {

    let start_date = value[0];
    let end_date = value[1];

    if (end_date <= start_date) {
      end_date = end_date.add(1, 'day')
    }

    start_date = start_date.format("YYYY-MM-DD HH:mm:ss");
    end_date = end_date.format("YYYY-MM-DD HH:mm:ss");
    return start_date + " - " + end_date;
  }
};

export const sliderToStringTimeFormat = (
  sliderValue = [10, 20],
  extraSecond = false
) => {
  if (typeof sliderValue === "string") {
    return ""
  } else {
    return `${moment(new Date()).format(
      "YYYY-MM-DD "
    )}${convertSliderValueToTime(sliderValue[0], extraSecond)} - ${moment(
      new Date()
    ).format("YYYY-MM-DD ")}${convertSliderValueToTime(
      sliderValue[1],
      extraSecond
    )}`
  }
}
