import { useRef } from "react";

// ant design
import {
  Form,
} from "antd";

// components
import InputDefault from "../components/Input/InputDefault.jsx";
import InputSelect from "../components/Input/InputSelect.jsx";
import { InputTimeRangePicker } from "../components/Input/InputTimeRangePicker.jsx";

// utils
import {
  defaultDayLoad,
  initialBuildingState,
  numberOnly,
  getOperationHours
} from "../utils/Helper.js";

// css
import "../assets/css/App.css";

const RenderFormBuilding = ({
    setActive,
    active,
    form,
    setDataHandle,
    DataHandle,
    isCheckedApplySame,
    setIsCheckedApplySame,
    buildingTypes,
    buildingTypeMix,
    containerRef,
    nightLoadAllDay,
    setNightLoadAllDay,
  }) => {
    // console.log("DATA HANDLE: ", DataHandle)
  
    const notesRef1 = useRef()
    const notesRef2 = useRef()
  
    const onCheckedChange = (field = "", index, defaultData = [...defaultDayLoad]) => {
      // new scheme
      let newDataHandle = [...DataHandle]
      let value = newDataHandle[index]?.[field]
  
      if (value.length > 0) {
        newDataHandle[index] = {
          ...newDataHandle[index],
          [field]: "",
        }
      } else {
        newDataHandle[index] = {
          ...newDataHandle[index],
          [field]: defaultData,
        }
      }
      setDataHandle(newDataHandle)
    }
  
    const onAfterChange = (field = "", index, time) => {
      // new scheme
      let newDataHandle = [...DataHandle]
      newDataHandle[index] = {
        ...newDataHandle[index],
        [field]: time,
      }
      setDataHandle(newDataHandle)
    }
  
    const onTimeChange = (field = "", index, time, type) => {
      // console.log("onTimeChange:", field, index, time, type);
      // new scheme
      let newDataHandle = [...DataHandle]
      // console.log("onTimeChange before newDataHandle:", JSON.parse(JSON.stringify(newDataHandle)));
      let tmp = newDataHandle[index][field];
      if (type === "start") {      
        tmp[0] = time;
      } else {
        tmp[1] = time;
      }
      newDataHandle[index] = {
        ...newDataHandle[index],
        [field]: tmp,
      }
      // console.log("onTimeChange after newDataHandle:", JSON.parse(JSON.stringify(newDataHandle)))
      setDataHandle(newDataHandle)
      return tmp;
    }
  
    return (
      <Form.List
        initialValue={[{ ...initialBuildingState }]}
        name="building_info"
      >
        {(fields, { add, remove }, meta) => {
          return (
            <div key={fields.key}>
              {/* render tab */}
              {/*<div className="mt-10 flex space-x-1">
                {fields.map((value, index) => {
                  return (
                    <div
                      key={index.toString()}
                      onClick={() => {
                        setActive(index)
                      }}
                      className={`${
                        active === index
                          ? "bg-[#00A6EF]  border-t-[1px] border-x-[1px] border-[#00000025]"
                          : "bg-[#85858565] border-t-[1px] border-x-[1px] border-[#00000025]"
                      } 
                      cursor-pointer 
                      p-6 min-w-fit rounded-t-2xl 
                      ${
                        fields.length > 3 ? "text-lg" : "text-xl"
                      } font-bold text-white 
                      transition
                      `}
                    >
                      Building {index + 1}
                    </div>
                  )
                })}
                <div
                  onClick={async () => {
                    let newData = await [...DataHandle]
                    await newData.push({
                      ...initialBuildingState,
                    })
  
                    await setDataHandle(newData)
                    await add({
                      ...initialBuildingState,
                    })
                  }}
                  className={`items-center cursor-pointer p-6 w-fit rounded-t-[15px] text-xl text-primary transition hover:scale-105`}
                >
                  <p>{fields.length > 3 ? "+" : "+ Add another building"}</p>
                </div 
              </div> */}
  
              {/* render form */}
              {fields.map(({ key, name, ...restField }, index) => {
                // from antd
                const buildingInfo = form.getFieldValue()["building_info"][index]
                // console.log(`old building info: ${index}`, buildingInfo)
                let operationNumbers = [1, 2];
                const isMixed = buildingInfo?.building_type === "Mixed Use";
                if (!isMixed) {
                  operationNumbers = [1];
                }
                // const hideFirstOperation = buildingInfo?.first_mix_type === "Hotel";
                // const hideSecondOperation = (buildingInfo?.second_mix_type === "Hotel" && isMixed || (!isMixed));
  
                // if (!isMixed && !hideFirstOperation && !hideSecondOperation) { operationNumbers = [1] }
                // else if (hideFirstOperation && !hideSecondOperation) { operationNumbers = [2] }
                // else if (!hideFirstOperation && hideSecondOperation) { operationNumbers = [1] }
                // else if (hideFirstOperation && hideSecondOperation) { operationNumbers = [] }
  
                return (
                  <div
                    ref={containerRef}
                    key={key.toString()}
                    onClick={() => {
                      setActive(index)
                    }}
                    className={`
                      ${
                        active === index
                          ? "bg-[#00A6EF] border-[1px] border-[#00000025]"
                          : "bg-[#858585ac] border-[1px] hidden overflow-hidden border-[#00000025]"
                      }
                      p-6 rounded-2xl text-[26px] text-white transition w-full`}
                  >
                    {/* <div className="mb-6 flex justify-between items-center">
                      <span className="text-2xl font-medium">
                        Tell us about your building
                      </span>
                      <button
                        disabled={fields.length === 1}
                        onClick={async () => {
                          let newDataHandle = [...DataHandle]
  
                          newDataHandle.length !== 1 &&
                            newDataHandle.splice(newDataHandle.length - 1, 1)
                          await setDataHandle(newDataHandle)
                          await setActive(newDataHandle.length - 1)
                          await remove(name)
                        }}
                        className={`${
                          fields.length !== 1
                            ? "border-primary text-primary"
                            : "border-gray-400 text-gray-400"
                        } border-2 p-2 rounded-2xl bg-white font-normal text-base`}
                      >
                        remove building
                      </button>
                      </div> */}
  
                    <div className="w-full p-6 rounded-2xl bg-white mb-10">
                      <div className="grid grid-cols-3 gap-6 border-b-2 pb-5 mb-5">
                        <Form.Item
                          {...restField}
                          name={[name, "building_location"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                            {
                              message: 'this field is required!',
                              validator: (_, value) => {
                                if (value !== "Select") {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('this field is required!');
                                }
                               }
                             }
                          ]}
                        >
                          <InputSelect
                            title="Location"
                            className="w-full"
                            options={[{ name: "Singapore" }]}
                            onChange={(text) => {
                              //if (text === "Select") { return; }
                              let newDataHandle = [...DataHandle]
                              newDataHandle[index] = {
                                ...newDataHandle[index],
                                building_location: text,
                              }
                              setDataHandle(newDataHandle)
                            }}
                          />
                        </Form.Item>
  
                        <Form.Item
                          {...restField}
                          className="w-full col-span-2"
                          name={[name, "address"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                          ]}
                        >
                          <InputDefault
                            // value={form.getFieldValue([name, "address"])}
                            title="Building name & address"
                            className="w-full col-span-2"
                            onChange={(text) => {
                              let newDataHandle = [...DataHandle]
                              newDataHandle[index] = {
                                ...newDataHandle[index],
                                address: text,
                              }
                              setDataHandle(newDataHandle)
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="grid grid-cols-3 gap-6">
                        <Form.Item
                          {...restField}
                          name={
                            isMixed
                              ? [name, "building_type"]
                              : [name, "first_mix_type"]
                          }
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                          ]}
                        >
                          <InputSelect
                            // value={value.building_type}
                            title="Type of Building"
                            className="w-full"
                            options={[...buildingTypes]}
                            onChange={(text) => {
                              let fieldsToUpdate = [
                                {
                                  name: ["building_info", name, "building_type"],
                                  value: text === "Mixed Use" ? text : "",
                                },
                                {
                                  name: ["building_info", name, "first_mix_type"],
                                  value: text === "Mixed Use" ? "Select" : text,
                                },
                              ];
                              let newDataHandle = [...DataHandle];
                              let objectToUpdate = {
                                ...newDataHandle[index],
                                building_type: text,
                              };
                              // If building type is mixed use, reset operation hours
                              if (text === "Mixed Use") {
                                objectToUpdate["first_mix_type"] = "";
                                objectToUpdate["second_mix_type"] = "";
                                objectToUpdate["second_gfa"] = 0;
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "second_gfa"],
                                  value: 0,
                                });
  
                                objectToUpdate["operation_hour_monday_friday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_1"] = "";
                                objectToUpdate["operation_hour_sun_ph_1"] = "";
  
                                objectToUpdate["operation_hour_monday_friday_2"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_2"] = "";
                                objectToUpdate["operation_hour_sun_ph_2"] = "";
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_1"],
                                  value: "",
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_1"],
                                  value: "",
                                });
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_2"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_2"],
                                  value: "",
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_2"],
                                  value: "",
                                });
                              }
                              // Update the operation hours of hotel to 24 hours
                              else if (text === "Hotel") {
                                objectToUpdate["operation_hour_monday_friday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_sun_ph_1"] = getOperationHours(text);
                                
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_1"],
                                  value: getOperationHours(text),
                                });
  
                              }
                              else if (text === "Retail") {
                                objectToUpdate["operation_hour_monday_friday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_sun_ph_1"] = getOperationHours(text);
                                
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_1"],
                                  value: getOperationHours(text),
                                });
                              } else {
                                objectToUpdate["operation_hour_monday_friday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_1"] = "";
                                objectToUpdate["operation_hour_sun_ph_1"] = "";
                                
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_1"],
                                  value: "",
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_1"],
                                  value: "",
                                });
                              }
  
                              if (text != "Mixed Use") {
                                objectToUpdate["building_type"] = "";
                                objectToUpdate["first_mix_type"] = text;
                                objectToUpdate["second_mix_type"] = "";
                                objectToUpdate["second_gfa"] = 0;
                                objectToUpdate["operation_hour_monday_friday_2"] = "";
                                objectToUpdate["operation_hour_saturday_2"] = "";
                                objectToUpdate["operation_hour_sun_ph_2"] = "";
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "second_gfa"],
                                  value: 0,
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_2"],
                                  value: "",
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_2"],
                                  value: "",
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_2"],
                                  value: "",
                                });
                              }
  
                              form.setFields(fieldsToUpdate);
                              newDataHandle[index] = objectToUpdate;
                              setDataHandle(newDataHandle);
                            }}
                          />
                        </Form.Item>
  
                        <Form.Item
                          hidden={isMixed}
                          {...restField}
                          className="w-full col-span-2"
                          name={[name, "first_gfa"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                            {
                              message: 'invalid value!',
                              validator: (_, value) => {
                                if (!value || numberOnly(value)) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('invalid value!');
                                }
                               }
                             }
                          ]}
                        >
                          <InputDefault
                            // value={value.first_gfa}
                            className="w-full col-span-2"
                            title={<p>Air-conditioned Gross Floor Area (m<sup>2</sup>)</p>}
                            formatWithComma={true}
                            onChange={(text) => {
                              let newDataHandle = [...DataHandle]
                              newDataHandle[index] = {
                                ...newDataHandle[index],
                                first_gfa: numberOnly(text),
                              }
                              setDataHandle(newDataHandle)
                            }}
                          />
                        </Form.Item>
                      </div>
  
                      <div className="grid grid-cols-3 gap-6">
                        <Form.Item
                          hidden={!isMixed}
                          {...restField}
                          name={[name, "first_mix_type"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                          ]}
                        >
                          <InputSelect
                            // value={value.first_mix_type}
                            title="First use"
                            className="w-full"
                            options={
                              [...DataHandle][index]?.second_mix_type
                                ? [...buildingTypeMix].filter(
                                    (item) =>
                                      !item.name.includes(
                                        form.getFieldValue()["building_info"][
                                          index
                                        ]?.second_mix_type
                                      )
                                  )
                                : [...buildingTypeMix]
                            }
                            onChange={(text) => {
                              // Update the main object as well as the form fields
                              let fieldsToUpdate = [];
                              let newDataHandle = [...DataHandle];
                              let objectToUpdate = newDataHandle[index];
                              objectToUpdate['first_mix_type'] = text;
                              // Update the operation hours of hotel type to 24 hours
                              if (text === "Hotel") {
                                objectToUpdate["operation_hour_monday_friday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_sun_ph_1"] = getOperationHours(text);
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_1"],
                                  value: getOperationHours(text),
                                });
                              }
                              // Update the operation hours of other types to default hours
                              else {
                                objectToUpdate["operation_hour_monday_friday_1"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_1"] = "";
                                objectToUpdate["operation_hour_sun_ph_1"] = "";
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_1"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_1"],
                                  value: "",
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_1"],
                                  value: "",
                                });
                              }
                              
                              form.setFields(fieldsToUpdate);
                              newDataHandle[index] = objectToUpdate;
                              setDataHandle(newDataHandle);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          hidden={!isMixed}
                          {...restField}
                          className="w-full col-span-2"
                          name={[name, "first_gfa"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                            {
                              message: 'invalid value!',
                              validator: (_, value) => {
                                if (!value || numberOnly(value)) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('invalid value!');
                                }
                               }
                             }
                          ]}
                        >
                          <InputDefault
                            // value={value.first_gfa}
                            title={<p>Air-conditioned Gross Floor Area (m<sup>2</sup>)</p>}
                            className="w-full col-span-2"
                            formatWithComma={true}
                            onChange={(text) => {
                              let newDataHandle = [...DataHandle]
                              newDataHandle[index] = {
                                ...newDataHandle[index],
                                first_gfa: numberOnly(text),
                              }
                              setDataHandle(newDataHandle)
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="grid grid-cols-3 gap-6 border-b-2 mb-5">
                        <Form.Item
                          hidden={!isMixed}
                          {...restField}
                          name={[name, "second_mix_type"]}
                          rules={[
                            {
                              required: false,
                              message: "this field is required!",
                            },
                          ]}
                        >
                          <InputSelect
                            // value={value.second_mix_type}
                            title="Second use"
                            className="w-full"
                            options={[...buildingTypeMix].filter(
                              (item) =>
                                !item.name.includes(buildingInfo?.first_mix_type)
                            )}
                            onChange={(text) => {
                              // Update the main object as well as the form fields
                              let fieldsToUpdate = [];
                              let newDataHandle = [...DataHandle];
                              let objectToUpdate = newDataHandle[index];
                              objectToUpdate['second_mix_type'] = text;
                              // Update the operation hours of hotel type to 24 hours
                              if (text === "Hotel") {
                                objectToUpdate["operation_hour_monday_friday_2"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_2"] = getOperationHours(text);
                                objectToUpdate["operation_hour_sun_ph_2"] = getOperationHours(text);
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_2"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_2"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_2"],
                                  value: getOperationHours(text),
                                });
                              }
                              else if (text === "Retail") {
                                objectToUpdate["operation_hour_monday_friday_2"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_2"] = getOperationHours(text);
                                objectToUpdate["operation_hour_sun_ph_2"] = getOperationHours(text);
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_2"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_2"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_2"],
                                  value: getOperationHours(text),
                                });
                              } 
                              // Update the operation hours of other types to default hours
                              else {
                                objectToUpdate["operation_hour_monday_friday_2"] = getOperationHours(text);
                                objectToUpdate["operation_hour_saturday_2"] = "";
                                objectToUpdate["operation_hour_sun_ph_2"] = "";
  
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_monday_friday_2"],
                                  value: getOperationHours(text),
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_saturday_2"],
                                  value: "",
                                });
                                fieldsToUpdate.push({
                                  name: ["building_info", name, "operation_hour_sun_ph_2"],
                                  value: "",
                                });
                              }
                              
                              form.setFields(fieldsToUpdate);
                              newDataHandle[index] = objectToUpdate;
                              setDataHandle(newDataHandle);
                            }}
                          />
                        </Form.Item>
  
                        <Form.Item
                          hidden={!isMixed}
                          {...restField}
                          className="w-full col-span-2"
                          name={[name, "second_gfa"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                            {
                              message: 'invalid value!',
                              validator: (_, value) => {
                                if (!value || numberOnly(value)) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('invalid value!');
                                }
                               }
                             }
                          ]}
                        >
                          <InputDefault
                            // value={value.second_gfa}
                            title={<p>Air-conditioned Gross Floor Area (m<sup>2</sup>)</p>}
                            className="w-full col-span-2"
                            formatWithComma={true}
                            onChange={(text) => {
                              let newDataHandle = [...DataHandle]
                              newDataHandle[index] = {
                                ...newDataHandle[index],
                                second_gfa: numberOnly(text),
                              }
                              setDataHandle(newDataHandle)
                            }}
                          />
                        </Form.Item>
                      </div>
  
                      <div className="grid grid-cols-3 gap-6 mb-5">
                        <Form.Item
                          {...restField}
                          name={[name, "electricity_tariff"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                            {
                              message: 'invalid value!',
                              validator: (_, value) => {
                                if (!value || numberOnly(value)) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('invalid value!');
                                }
                               }
                             }
                          ]}
                        >
                          <InputDefault
                            // value={value.electricity_tariff}
                            title="Electricity Tariff ($/kWh)"
                            className="w-full"
                            onChange={(text) => {
                              let newDataHandle = [...DataHandle]
                              newDataHandle[index] = {
                                ...newDataHandle[index],
                                electricity_tariff: numberOnly(text),
                              }
                              setDataHandle(newDataHandle)
                            }}
                            prepend={"$"}
                          />
                        </Form.Item>
  
                        <Form.Item
                          {...restField}
                          className="w-full col-span-2"
                          name={[name, "water_tariff"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required!",
                            },
                            {
                              message: 'invalid value!',
                              validator: (_, value) => {
                                if (!value || numberOnly(value)) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('invalid value!');
                                }
                               }
                             }
                          ]}
                        >
                          <InputDefault
                            // value={value.water_tariff}
                            title={<p>Water Tariff ($/m<sup>3</sup>)</p>}
                            className="w-full col-span-2"
                            onChange={(text) => {
                              let newDataHandle = [...DataHandle]
                              newDataHandle[index] = {
                                ...newDataHandle[index],
                                water_tariff: numberOnly(text),
                              }
                              setDataHandle(newDataHandle)
                            }}
                            prepend={"$"}
                          />
                        </Form.Item>
                      </div>
                    </div>
  
                    {/* divider */}
                    <div className="border-b-2 border-b-white mb-10" />
                    {/* <p style={{ fontSize: "14px" }}>{JSON.stringify(DataHandle, null, 4)}</p> */}
                    {(operationNumbers).map((number) => {
                      const isFirstUseAndSecondUseSameValue = () => {
                        let newDataHandle = [...DataHandle]
                        let fieldList = [
                          "night_load_area",
                          "night_load_monday_friday",
                          "night_load_saturday",
                          "night_load_sun_ph",
                          "operation_hour_monday_friday",
                          "operation_hour_saturday",
                          "operation_hour_sun_ph",
                        ]
                        let booleanList = fieldList.map((field) => {
                          return (
                            JSON.stringify(newDataHandle[index][`${field}_1`]) ===
                            JSON.stringify(newDataHandle[index][`${field}_2`])
                          )
                        })
                        const isEveryFieldTrue = booleanList.every(
                          (boolean) => boolean
                        )
  
                        return (
                          isEveryFieldTrue && isCheckedApplySame && number === 2
                        ) // is every field have same value, and field number 2
                      }
  
                      const areAllDaysTheSame = () => {
                        if (number === 1 && DataHandle[index]["first_mix_type"] === "Retail") {
                          return true;
                        }
                        else if (number === 2 && DataHandle[index]["second_mix_type"] === "Retail") {
                          return true;
                        }
                        return false;
                      }
  
                      const hideSaturdayOperation = () => {
                        if (number === 1 && DataHandle[index]["first_mix_type"] === "Retail") {
                          return true;
                        }
                        else if (number === 2 && DataHandle[index]["second_mix_type"] === "Retail") {
                          return true;
                        }
                        return false;
                      }
  
                      const hidePHOperation = () => {
                        if (number === 1 && DataHandle[index]["first_mix_type"] === "Retail") {
                          return true;
                        }
                        else if (number === 2 && DataHandle[index]["second_mix_type"] === "Retail") {
                          return true;
                        }
                        return false;
                      }
  
                      const calculateDefaultDayLoad = () => {
                        if (number === 1) {
                          return getOperationHours(DataHandle[index]["first_mix_type"]);
                        }
                        else if (number === 2) {
                          return getOperationHours(DataHandle[index]["second_mix_type"]);
                        }
                        else {
                          return getOperationHours();
                        }
                      }
  
                      return (
                        <div className="border-b-2 border-b-white mb-4 pb-0">
                          {operationNumbers.length === 1 && number === 1 ? "" : 
                            <div className="mb-6 text-2xl font-medium bg-[#BBE8FF] p-2 text-[#1C348A] rounded-lg">
                            For building {number === 1 ? "1st" : "2nd"} mix type:
                            {number === 1
                              ? DataHandle[index]?.first_mix_type !== "Select" ? DataHandle[index]?.first_mix_type : ""
                              : DataHandle[index]?.second_mix_type !== "Select" ? DataHandle[index]?.second_mix_type : ""}
                            {/* {number === 2 && (
                              <>
                                <br />
                                <Checkbox
                                  checked={isCheckedApplySame}
                                  onChange={(e) => {
                                    let checked = e.target.checked
                                    let newDataHandle = [...DataHandle]
  
                                    if (checked) {
                                      newDataHandle[index] = {
                                        ...newDataHandle[index],
                                        operation_hour_monday_friday_2:
                                          newDataHandle[index]
                                            ?.operation_hour_monday_friday_1,
  
                                        operation_hour_saturday_2:
                                          newDataHandle[index]
                                            ?.operation_hour_saturday_1,
  
                                        operation_hour_sun_ph_2:
                                          newDataHandle[index]
                                            ?.operation_hour_sun_ph_1,
  
                                        night_load_monday_friday_2:
                                          newDataHandle[index]
                                            ?.night_load_monday_friday_1,
  
                                        night_load_saturday_2:
                                          newDataHandle[index]
                                            ?.night_load_saturday_1,
  
                                        night_load_sun_ph_2:
                                          newDataHandle[index]
                                            ?.night_load_sun_ph_1,
  
                                        night_load_area_2:
                                          newDataHandle[index]?.night_load_area_1,
                                      }
                                      setIsCheckedApplySame(!isCheckedApplySame)
  
                                      let fieldsToUpdate = [
                                        {
                                          name: ["building_info", index, `operation_hour_monday_friday_2`],
                                          value: newDataHandle[index]?.operation_hour_monday_friday_1
                                        },
                                        {
                                          name: ["building_info", index, `operation_hour_saturday_2`],
                                          value: newDataHandle[index]?.operation_hour_saturday_1
                                        },
                                        {
                                          name: ["building_info", index, `operation_hour_sun_ph_2`],
                                          value: newDataHandle[index]?.operation_hour_sun_ph_1
                                        },
                                        {
                                          name: ["building_info", index, `night_load_monday_friday_2`],
                                          value: newDataHandle[index]?.night_load_monday_friday_1
                                        },
                                        {
                                          name: ["building_info", index, `night_load_saturday_2`],
                                          value: newDataHandle[index]?.night_load_saturday_1
                                        },
                                        {
                                          name: ["building_info", index, `night_load_sun_ph_2`],
                                          value: newDataHandle[index]?.night_load_sun_ph_1
                                        },
                                      ];
          
                                      form.setFields(fieldsToUpdate);
  
                                    }
                                    setIsCheckedApplySame(!isCheckedApplySame)
                                    setDataHandle(newDataHandle)
                                  }}
                                />
                                <span className="text-base ml-2">
                                  Apply the same as 1st mix type
                                </span>
                              </>
                            )} */}
                          </div>
                          }
  
                          <div className="mb-6 flex justify-between items-center text-2xl font-medium">
                            What are your operation hours?
                          </div>
  
                          {/* to disable click */}
                          <div
                            className={`w-full p-6 rounded-2xl bg-white ${
                              isFirstUseAndSecondUseSameValue()
                                ? "grayscale pointer-events-none"
                                : ""
                            } mb-10`}
                          >
                            <Form.Item
                              {...restField}
                              name={[
                                name,
                                `operation_hour_monday_friday_${number}`,
                              ]}
                              rules={[
                                {
                                  required: false,
                                  message: "this field is required!",
                                },
                              ]}
                            >
                              <InputTimeRangePicker
                                // title={areAllDaysTheSame() === true ? "Every day" : "Monday - Friday"}
                                title={"Monday - Friday"}
                                className="w-full col-span-2"
                                isChecked={
                                  ![...DataHandle][index]?.[
                                    `operation_hour_monday_friday_${number}`
                                  ]
                                }
                                onCheckedChange={() => {
                                  onCheckedChange(
                                    `operation_hour_monday_friday_${number}`,
                                    index,
                                    calculateDefaultDayLoad()
                                  );
                                  // if (areAllDaysTheSame()) {
                                  //   onCheckedChange(
                                  //     `operation_hour_saturday_${number}`,
                                  //     index,
                                  //     calculateDefaultDayLoad()
                                  //   );
                                  //   onCheckedChange(
                                  //     `operation_hour_sun_ph_${number}`,
                                  //     index,
                                  //     calculateDefaultDayLoad()
                                  //   );
                                  // }
  
                                  let fieldsToUpdate = [];
                                  fieldsToUpdate.push({
                                    name: ["building_info", index, `operation_hour_monday_friday_${number}`],
                                    value: calculateDefaultDayLoad(),
                                  });
                                  // if (areAllDaysTheSame()) { 
                                  //   fieldsToUpdate.push({
                                  //     name: ["building_info", index, `operation_hour_saturday_${number}`],
                                  //     value: calculateDefaultDayLoad(),
                                  //   });
                                  //   fieldsToUpdate.push({
                                  //     name: ["building_info", index, `operation_hour_sun_ph_${number}`],
                                  //     value: calculateDefaultDayLoad(),
                                  //   });
                                  // }
      
                                  form.setFields(fieldsToUpdate);
                                }}
                                // onAfterChange={(time) => {
                                //   onAfterChange(
                                //     `operation_hour_monday_friday_${number}`,
                                //     index,
                                //     time
                                //   )
                                // }}
                                onTimeChange={(time, type) => {
                                  let val = onTimeChange(
                                    `operation_hour_monday_friday_${number}`,
                                    index,
                                    time,
                                    type
                                  );
                                  let fieldsToUpdate = [];
                                  fieldsToUpdate.push({
                                    name: ["building_info", index, `operation_hour_monday_friday_${number}`],
                                    value: val,
                                  });
  
                                  // if (areAllDaysTheSame()) {
                                  //   let val2 = onTimeChange(
                                  //     `operation_hour_saturday_${number}`,
                                  //     index,
                                  //     time,
                                  //     type
                                  //   );
                                  //   fieldsToUpdate.push({
                                  //     name: ["building_info", index, `operation_hour_saturday_${number}`],
                                  //     value: val2,
                                  //   });
  
                                  //   let val3 = onTimeChange(
                                  //     `operation_hour_sun_ph_${number}`,
                                  //     index,
                                  //     time,
                                  //     type
                                  //   );
                                  //   fieldsToUpdate.push({
                                  //     name: ["building_info", index, `operation_hour_sun_ph_${number}`],
                                  //     value: val3,
                                  //   });
                                  // }
      
                                  form.setFields(fieldsToUpdate);
                                }}
                                value={
                                  [...DataHandle][index][
                                    `operation_hour_monday_friday_${number}`
                                  ]
                                }
                              />
                            </Form.Item>
  
                            <Form.Item
                              {...restField}
                              name={[name, `operation_hour_saturday_${number}`]}
                              rules={[
                                {
                                  required: false,
                                  message: "this field is required!",
                                },
                              ]}
                              // className={`${hideSaturdayOperation() ? "hidden" : ""}`}
                            >
                              <InputTimeRangePicker
                                title="Saturday"
                                className="w-full col-span-2"
                                isChecked={
                                  ![...DataHandle][index]?.[
                                    `operation_hour_saturday_${number}`
                                  ]
                                }
                                onCheckedChange={() => {
                                  onCheckedChange(
                                    `operation_hour_saturday_${number}`,
                                    index,
                                    calculateDefaultDayLoad()
                                  );
                                  let fieldsToUpdate = [];
                                  fieldsToUpdate.push({
                                    name: ["building_info", index, `operation_hour_saturday_${number}`],
                                    value: calculateDefaultDayLoad(),
                                  });
      
                                  form.setFields(fieldsToUpdate);
                                }}
                                // onAfterChange={(time) => {
                                //   onAfterChange(
                                //     `operation_hour_saturday_${number}`,
                                //     index,
                                //     time
                                //   )
                                // }}
                                onTimeChange={(time, type) => {
                                  let val = onTimeChange(
                                    `operation_hour_saturday_${number}`,
                                    index,
                                    time,
                                    type
                                  );
                                  let fieldsToUpdate = [];
                                  fieldsToUpdate.push({
                                    name: ["building_info", index, `operation_hour_saturday_${number}`],
                                    value: val,
                                  });
      
                                  form.setFields(fieldsToUpdate);
                                }}
                                value={
                                  [...DataHandle][index][
                                    `operation_hour_saturday_${number}`
                                  ]
                                }
                              />
                            </Form.Item>
  
                            <Form.Item
                              {...restField}
                              name={[name, `operation_hour_sun_ph_${number}`]}
                              rules={[
                                {
                                  required: false,
                                  message: "this field is required!",
                                },
                              ]}
                              // className={`${hidePHOperation() ? "hidden" : ""}`}
                            >
                              <InputTimeRangePicker
                                title="Sunday & Public Holiday"
                                className="w-full col-span-2"
                                isChecked={
                                  ![...DataHandle][index]?.[
                                    `operation_hour_sun_ph_${number}`
                                  ]
                                }
                                onCheckedChange={() => {
                                  onCheckedChange(
                                    `operation_hour_sun_ph_${number}`,
                                    index,
                                    calculateDefaultDayLoad()
                                  );
                                  let fieldsToUpdate = [];
                                  fieldsToUpdate.push({
                                    name: ["building_info", index, `operation_hour_sun_ph_${number}`],
                                    value: calculateDefaultDayLoad(),
                                  });
      
                                  form.setFields(fieldsToUpdate);
                                }}
                                // onAfterChange={(time) => {
                                //   onAfterChange(
                                //     `operation_hour_sun_ph_${number}`,
                                //     index,
                                //     time
                                //   )
                                // }}
                                onTimeChange={(time, type) => {
                                  let val = onTimeChange(
                                    `operation_hour_sun_ph_${number}`,
                                    index,
                                    time,
                                    type
                                  );
                                  let fieldsToUpdate = [];
                                  fieldsToUpdate.push({
                                    name: ["building_info", index, `operation_hour_sun_ph_${number}`],
                                    value: val,
                                  });
      
                                  form.setFields(fieldsToUpdate);
                                }}
                                value={
                                  [...DataHandle][index][
                                    `operation_hour_sun_ph_${number}`
                                  ]
                                }
                              />
                            </Form.Item>
                          </div>
                          
                          {/* <div className="flex justify-between items-center text-2xl font-medium mb-4">
                            Additional cooling requirements
                          </div>
  
                          <div className="flex justify-between items-center text-base font-medium">
                            Are there any tenants in your building that require cooling after normal hours?
                          </div>
  
                          <div className="flex justify-between items-center text-base font-medium mb-6">
                          If so, please indicate the hours they require cooling.
                          </div>
  
                          // to disable click
                          <div
                            className={`w-full p-6 rounded-2xl bg-white ${
                              isFirstUseAndSecondUseSameValue()
                                ? "grayscale pointer-events-none"
                                : ""
                            }`}
                          >
                            <div className="text-lg text-primary font-bold">
                              Night load for all days
                            </div>
  
                            // <Radio.Group
                            //   buttonStyle={{ marginLeft: 100 }}
                            //   onChange={(e) => {
                            //     console.log("radio checked", e.target.value)
                            //     setNightLoadAllDay(e.target.value)
  
                            //     // new scheme
                            //     let newDataHandle = [...DataHandle]
  
                            //     if (e.target.value === "off") {
                            //       newDataHandle[index][
                            //         `night_load_monday_friday_${number}`
                            //       ] = ""
                            //       newDataHandle[index][
                            //         `night_load_saturday_${number}`
                            //       ] = ""
                            //       newDataHandle[index][
                            //         `night_load_sun_ph_${number}`
                            //       ] = ""
                            //     } else {
                            //       newDataHandle[index][
                            //         `night_load_monday_friday_${number}`
                            //       ] = defaultNightLoad
                            //       newDataHandle[index][
                            //         `night_load_saturday_${number}`
                            //       ] = defaultNightLoad
                            //       newDataHandle[index][
                            //         `night_load_sun_ph_${number}`
                            //       ] = defaultNightLoad
                            //     }
                            //     setDataHandle(newDataHandle)
                            //   }}
                            //   value={nightLoadAllDay}
                            // >
                            //   <Radio
                            //     className="text-lg text-primary font-bold"
                            //     value={"off"}
                            //   >
                            //     Off
                            //   </Radio>
                            //   <Radio
                            //     className="text-lg text-primary font-bold"
                            //     value={"custom"}
                            //   >
                            //     Set custom
                            //   </Radio>
                            // </Radio.Group>
  
                            <Checkbox
                              checked={nightLoadAllDay === "off" ? true : false}
                              className="text-lg text-primary font-bold"
                              onChange={(e) => {
                                setNightLoadAllDay(
                                  e.target.checked ? "off" : "custom"
                                )
  
                                // new scheme
                                let newDataHandle = [...DataHandle]
  
                                if (e.target.checked) {
                                  newDataHandle[index][
                                    `night_load_monday_friday_${number}`
                                  ] = ""
                                  newDataHandle[index][
                                    `night_load_saturday_${number}`
                                  ] = ""
                                  newDataHandle[index][
                                    `night_load_sun_ph_${number}`
                                  ] = ""
                                } else {
                                  newDataHandle[index][
                                    `night_load_monday_friday_${number}`
                                  ] = defaultNightLoad
                                  newDataHandle[index][
                                    `night_load_saturday_${number}`
                                  ] = defaultNightLoad
                                  newDataHandle[index][
                                    `night_load_sun_ph_${number}`
                                  ] = defaultNightLoad
                                }
                                setDataHandle(newDataHandle)
                              }}
                            >
                              OFF
                            </Checkbox>
  
                            <Form.Item
                              {...restField}
                              name={[name, `night_load_monday_friday_${number}`]}
                              rules={[
                                {
                                  required: false,
                                  message: "this field is required!",
                                },
                              ]}
                            >
                              <InputRangeSlider
                                isNightLoad
                                title="Monday - Friday"
                                className="w-full col-span-2"
                                isChecked={
                                  ![...DataHandle][index]?.[
                                    `night_load_monday_friday_${number}`
                                  ]
                                }
                                onCheckedChange={() => {
                                  onCheckedChange(
                                    `night_load_monday_friday_${number}`,
                                    index,
                                    defaultNightLoad
                                  )
                                }}
                                onAfterChange={(time) => {
                                  onAfterChange(
                                    `night_load_monday_friday_${number}`,
                                    index,
                                    time
                                  )
                                }}
                                sliderValue={
                                  [...DataHandle][index][
                                    `night_load_monday_friday_${number}`
                                  ]
                                }
                              />
                            </Form.Item>
  
                            <Form.Item
                              {...restField}
                              name={[name, `night_load_saturday_${number}`]}
                              rules={[
                                {
                                  required: false,
                                  message: "this field is required!",
                                },
                              ]}
                            >
                              <InputRangeSlider
                                isNightLoad
                                title="Saturday"
                                className="w-full col-span-2"
                                isChecked={
                                  ![...DataHandle][index]?.[
                                    `night_load_saturday_${number}`
                                  ]
                                }
                                onCheckedChange={() => {
                                  onCheckedChange(
                                    `night_load_saturday_${number}`,
                                    index,
                                    defaultNightLoad
                                  )
                                }}
                                onAfterChange={(time) => {
                                  onAfterChange(
                                    `night_load_saturday_${number}`,
                                    index,
                                    time
                                  )
                                }}
                                sliderValue={
                                  [...DataHandle][index][
                                    `night_load_saturday_${number}`
                                  ]
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, `night_load_sun_ph_${number}`]}
                              rules={[
                                {
                                  required: false,
                                  message: "this field is required!",
                                },
                              ]}
                            >
                              <InputRangeSlider
                                isNightLoad
                                title="Sunday"
                                className="w-full col-span-2"
                                isChecked={
                                  ![...DataHandle][index]?.[
                                    `night_load_sun_ph_${number}`
                                  ]
                                }
                                onCheckedChange={() => {
                                  onCheckedChange(
                                    `night_load_sun_ph_${number}`,
                                    index,
                                    defaultNightLoad
                                  )
                                }}
                                onAfterChange={(time) => {
                                  onAfterChange(
                                    `night_load_sun_ph_${number}`,
                                    index,
                                    time
                                  )
                                }}
                                sliderValue={
                                  [...DataHandle][index][
                                    `night_load_sun_ph_${number}`
                                  ]
                                }
                              />
                            </Form.Item>
  
                            <div className="text-lg text-primary font-bold">
                              Area of night load (m3)
                            </div>
  
                            <Form.Item
                              {...restField}
                              name={[name, `night_load_area_${number}`]}
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required!",
                                },
                              ]}
                            >
                              <div className="flex items-center">
                                <Radio.Group
                                  defaultValue={"Not sure"}
                                  onChange={(e) => {
                                    let newDataHandle = [...DataHandle]
                                    if (e.target.value === "Please specify") {
                                      ;(number === 1
                                        ? notesRef1
                                        : notesRef2
                                      )?.current?.focus()
                                    } else {
                                      newDataHandle[index] = {
                                        ...newDataHandle[index],
                                        [`night_load_area_${number}`]:
                                          e.target.value,
                                      }
                                    }
                                    setDataHandle(newDataHandle)
                                  }}
                                  className="w-2/3 flex"
                                >
                                  <Radio
                                    value={"Not sure"}
                                    className="text-lg text-primary font-bold"
                                  >
                                    Not sure
                                  </Radio>
                                  <Radio
                                    value={"Please specify"}
                                    className="text-lg text-primary font-bold"
                                  >
                                    Please specify
                                  </Radio>
                                </Radio.Group>
  
                                <input
                                  ref={number === 1 ? notesRef1 : notesRef2}
                                  tabIndex={`notes_${number}`}
                                  type="text"
                                  className={`h-10 p-3 border rounded-lg w-full`}
                                  onChange={(e) => {
                                    let newDataHandle = [...DataHandle]
                                    newDataHandle[index] = {
                                      ...newDataHandle[index],
                                      [`night_load_area_${number}`]:
                                        e.target.value,
                                    }
  
                                    setDataHandle(newDataHandle)
                                  }}
                                />
                              </div>
                            </Form.Item>
                          </div> */}
                          
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          )
        }}
      </Form.List>
    )
}

export default RenderFormBuilding;