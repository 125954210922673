import { memo } from "react";
import { useInView } from "react-intersection-observer";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const InputPhone = ({ id, value, className, title = "title", onChange }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
  })

  
  return (
    <div id={id} ref={ref} data-inview={inView} className={`${className}`}>
        <h6 className="text-sm text-primary font-bold mb-2">{title}</h6>

        <PhoneInput
            country={'sg'}
            value={value}
            onChange={(value, country, e, formattedValue) => {
                onChange(formattedValue);
            }}
        />
    </div>
  )
}

export default memo(InputPhone)
