export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const API_USERNAME = process.env.REACT_APP_API_USERNAME;
export const API_PASSWORD = process.env.REACT_APP_API_PASSWORD;
export const API_ROUTES = {
    GET_CSRF: `${API_DOMAIN}/kaer-amadeus/auth/csrf/`,
    GET_BUILDING_TYPE: `${API_DOMAIN}/kaer-amadeus/api/building_type/`,
    CREATE_SUBMISSION: `${API_DOMAIN}/kaer-amadeus/api/submission/`,
    OBTAIN_TOKEN: `${API_DOMAIN}/kaer-amadeus/api/token/`,
    REFRESH_TOKEN: `${API_DOMAIN}/kaer-amadeus/api/token/refresh/`,
    VERIFY_TOKEN: `${API_DOMAIN}/kaer-amadeus/api/token/verify/`,
};
export const VERSION_NO = process.env.REACT_APP_VERSION_NO;