import { useCallback, useEffect, useRef, useState } from "react";

// ant design
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Modal,
  Radio,
  notification,
  Switch,
} from "antd";
import { useForm } from "antd/es/form/Form.js"
import axios from "axios";

// logo
import Background from "../assets/img/background.png";
import DoneIcon from "../assets/img/done.svg";
import InformationMark from "../assets/img/informationMark.png";
import LinkedInIcon from "../assets/img/linkedin.svg";
import Logo from "../assets/img/WhiteLogo.png";
import QuestionMark from "../assets/img/questionMark.png";
import Scheme from "../assets/img/scheme.png";
import YoutubeIcon from "../assets/img/youtube.svg";

// components
import RenderFormBuilding from "./renderFormBuilding.jsx";
import InputDefault from "../components/Input/InputDefault.jsx";
import InputPhone from "../components/Input/InputPhone.jsx";
import { ProductButton } from "../components/selectProduct/ProductButton.jsx";

// libs
import { useInView } from "react-intersection-observer";

// utils
import {
  baseUrl,
  initialBuildingState,
  dayjsDateToString
} from "../utils/Helper.js";
import {
  API_ROUTES,
  API_USERNAME,
  API_PASSWORD,
  VERSION_NO
} from "../utils/config.js";

// css
import "../assets/css/App.css";

function App() {
  // hooks
  const [form] = useForm();
  const [api, contextHolder] = notification.useNotification();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  // local state
  const [DataHandle, setDataHandle] = useState([initialBuildingState]);
  const [buildingTypes, setBuildingTypes] = useState([]);
  const [buildingTypeMix, setBuildingTypeMix] = useState([]);
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isCheckedApplySame, setIsCheckedApplySame] = useState(false);
  const [open, setOpen] = useState(false);
  const [FristLoad, setFristLoad] = useState(true);
  const [nightLoadAllDay, setNightLoadAllDay] = useState("off");
  const [openSubmissionAlert, setOpenSubmissionAlert] = useState(false);
  const [submissionID, setSubmissionID] = useState("");

  const handleBackToHomeClicked = () => {
    let newObject = {
      company: "",
      designation: "",
      email: "",
      name: "",
      telephone: "",
      building_info: [{...initialBuildingState}],
    };
    form.resetFields();
    form.setFieldsValue(newObject);
    setDataHandle([{...initialBuildingState}]);
    setOpenSubmissionAlert(false);
    setSubmissionID("");
    window.location.href = "https://www.kaer.com/";
  }

  const handleSubmitAnotherClicked = () => {
    // console.log("handleSubmitAnotherClicked: ", form.getFieldsValue());
    let newObject = {
      ...form.getFieldsValue(),
      building_info: [{...initialBuildingState}],
    };
    form.resetFields();
    form.setFieldsValue(newObject);
    setDataHandle([{...initialBuildingState}]);
    setOpenSubmissionAlert(false);
    setSubmissionID("");
  }
 
  const handleCancel = () => {
    setOpen(false)
  }

  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  // const [csrfToken, setCsrfToken] = useState('');

  // get token
  const getAllTokens = (username, password) => {
    return axios.post(
      API_ROUTES.OBTAIN_TOKEN,
      {
        username, password
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  };

  // verify token
  const verifyAToken = (token) => {
    return axios.post(
      API_ROUTES.VERIFY_TOKEN,
      {
        token,
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  };

  // refresh token
  const refreshAccessToken = (token) => {
    return axios.post(
      API_ROUTES.REFRESH_TOKEN,
      {
        refresh: token
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  };

  // csrf token
  const getCsrfToken = (token) => {
    return axios.get(
      API_ROUTES.GET_CSRF,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    )
  }

  // building type
  const getBuildingType = (token, withParams) => {
    let url = API_ROUTES.GET_BUILDING_TYPE;

    if (withParams === true) {
      url += "?usage=single";
    }

    return axios.get(
      url,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    )
  }

  const loadData = async () => {
    setLoading(true);

    try {
      // Get the access tokens
      const tokenResponse = await getAllTokens(API_USERNAME, API_PASSWORD);
      // console.log("Token Response:", tokenResponse);

      const access_token = tokenResponse.data.access;
      setAccessToken(tokenResponse.data.access);
      setRefreshToken(tokenResponse.data.refresh);

      // After we get the tokens, we can call the other APIs.
      // csrf token, building types for all, building types for mixed
      Promise.all([
        // getCsrfToken(access_token),
        getBuildingType(
          access_token, 
          true
        ),
        getBuildingType(
          access_token, 
          false
        )
      ]).then((responses) => {
        // console.log("Load Data Responses:", responses);
        const [
          // csrfResponse,
          buildingTypeMixedResponse,
          buildingTypeResponse
        ] = responses;
        
        // setCsrfToken(csrfResponse.data.csrf);
        setBuildingTypeMix(buildingTypeMixedResponse.data.results);
        setBuildingTypes(buildingTypeResponse.data.results);

        setLoading(false);
      })
    } catch (error) {
      console.log("Load Data Error:", error); 
      api.error({
        message: error.name,
        description: error.message,
        onClose: () => setLoading(false),
      })
    }
  }

  useEffect(() => {
    if (FristLoad) {
      loadData();
      setFristLoad(false);
    }
  }, [])

  // submit
  const onFinish = async (values) => {
    setLoading(true);

    // check if access token is still valid
    let is_refresh_token_valid = true;
    let access_token = accessToken;
    let need_refresh = false;
    // console.log("Previous access token:", access_token);

    try {
      const verifyTokenResponse = await verifyAToken(access_token);
      // console.log('Verify Token Response:', verifyTokenResponse);
      
    } catch (error) {
      need_refresh = true;
      console.log('Verify Token Error:', error);
    }

    if (need_refresh === true) {
      try {
        const verifyRefTokenResponse = await verifyAToken(refreshToken);
        // console.log('Verify Refresh Token Response:', verifyRefTokenResponse);
        
      } catch (error) {
        is_refresh_token_valid = true;
        console.log('Verify Refresh Token Error:', error);
      }
    }

    try{
      if (need_refresh === true && is_refresh_token_valid === false) {
        const refreshAccessTokenResponse = await refreshAccessToken(refreshToken);
        // console.log('Refresh Access Token Response:', refreshAccessTokenResponse);

        setAccessToken(refreshAccessTokenResponse.data.access);
        access_token = refreshAccessTokenResponse.data.access;
        // console.log("Refreshed access token:", access_token);
      }
      // when we need to refresh all the tokens
      else if (need_refresh === true && is_refresh_token_valid === true) {
        const tokenResponse = await getAllTokens(API_USERNAME, API_PASSWORD);
        // console.log("Token Response:", tokenResponse);

        access_token = tokenResponse.data.access;
        setAccessToken(tokenResponse.data.access);
        setRefreshToken(tokenResponse.data.refresh);
      }
    } catch (error) {
      console.error("Refresh Token Error:", error);

      api.error({
        message: error.name,
        description: error.message,
        onClose: () => setLoading(false),
      });
      
      return;
    }

    const newDataHandle = [...DataHandle].map((data, index) => {
      return {
        ...data,
        operation_hour_monday_friday_1: dayjsDateToString(
          data.operation_hour_monday_friday_1,
          true
        ),
        operation_hour_monday_friday_2: dayjsDateToString(
          data.operation_hour_monday_friday_2,
          true
        ),
        operation_hour_saturday_1: dayjsDateToString(
          data.operation_hour_saturday_1,
          true
        ),
        operation_hour_saturday_2: dayjsDateToString(
          data.operation_hour_saturday_2,
          true
        ),
        operation_hour_sun_ph_1: dayjsDateToString(
          data.operation_hour_sun_ph_1,
          true
        ),
        operation_hour_sun_ph_2: dayjsDateToString(
          data.operation_hour_sun_ph_2,
          true
        ),
        night_load_monday_friday_1: dayjsDateToString(
          data.night_load_monday_friday_1,
          true
        ),
        night_load_monday_friday_2: dayjsDateToString(
          data.night_load_monday_friday_2,
          true
        ),
        night_load_saturday_1: dayjsDateToString(
          data.night_load_saturday_1,
          true
        ),
        night_load_saturday_2: dayjsDateToString(
          data.night_load_saturday_2,
          true
        ),
        night_load_sun_ph_1: dayjsDateToString(
          data.night_load_sun_ph_1,
          true
        ),
        night_load_sun_ph_2: dayjsDateToString(
          data.night_load_sun_ph_2,
          true
        ),
      }
    })

    let body = {
      ...values,
      type: "air",
      building_info: newDataHandle,
    }

    console.log("Payload:", body);
    // setLoading(false); return;

    axios
      .post(
        API_ROUTES.CREATE_SUBMISSION, 
        body,
        {
          headers: {
            "Authorization": `Bearer ${access_token}`
          }
        }
      )
      .then((res) => {
        console.log("Results:", res);
        setLoading(false);
        setSubmissionID(res.data.results.submission_id || "");
        setOpenSubmissionAlert(true);
        
      })
      .catch((err) => {
        console.error(err, "error res")

        api.error({
          message: err.name,
          description: err.message,
          onClose: () => setLoading(false),
        })
      })
  }

  const RenderBuildingCallback = useCallback(
    () => (
      <RenderFormBuilding
        containerRef={ref}
        DataHandle={DataHandle}
        setDataHandle={setDataHandle}
        isCheckedApplySame={isCheckedApplySame}
        setIsCheckedApplySame={setIsCheckedApplySame}
        active={active}
        setActive={setActive}
        buildingTypes={buildingTypes}
        buildingTypeMix={buildingTypeMix}
        form={form}
        nightLoadAllDay={nightLoadAllDay}
        setNightLoadAllDay={setNightLoadAllDay}
      />
    ),
    [
      active,
      DataHandle,
      form,
      buildingTypes,
      buildingTypeMix,

      ref,
      nightLoadAllDay,
      //
    ]
  )

  return (
    <ConfigProvider
      theme={{
        token: {
          /* here is your global tokens */
          colorBgSpotlight: "rgba(0, 93, 170, 1)",
          colorPrimary: "#005DAA",
          fontFamily: "Roboto",
        },
        components: {
          Slider: {
            /* here is your component tokens */
            railSize: 1, // border size
            railBg: "rgba(0, 93, 170, 0.5)", // border color
            railHoverBg: "rgba(0, 93, 170, 1)", // border hover color
            handleActiveColor: "rgba(0, 93, 170, 1)",
            handleSize: 16,
            handleSizeHover: 20,
            dotSize: 20,
            handleLineWidth: 8,
            handleColor: "rgba(0, 93, 170, 1)",
            handleLineWidthHover: 8,
            trackHoverBg: "rgba(0, 93, 170, 1)",
          },
          Switch: {
            colorPrimaryHover: "#CCCCCC",
          },
        },
        Radio: {
          /* here is your component tokens */
          // buttonColor: "rgba(0, 93, 170, 1)",
          // wrapperMarginInlineEnd: 100,
        },
      }}
    >
      {contextHolder}

      {/* main container */}
      <div className="flex min-h-screen max-h-screen fixed overflow-y-hidden">
        {/* kaer logo */}
        <div className="absolute px-10 py-10">
          <img alt="" height={180} width={180} src={Logo} />
        </div>

        <div className="absolute w-full h-16 bottom-gray-background z-1"></div>

        {/* help */}
        <div className="absolute right-0 px-8 py-6 text-[#CDCDCD] z-2">
          <a href="#help" className="flex items-center">
            Need help? Speak to a member of our team
            <img className="w-3 h-3 ml-2" src={InformationMark} alt="" />
          </a>
        </div>

        {/* for debugging purposes */}
        {/* <div className="bg-white z-10 overflow-y-scroll min-w-[35%]">
          <Form.Item noStyle shouldUpdate>
            {() => (
              <Typography className="bg-yellow-500">
                <pre>
                  {JSON.stringify(
                    {
                      ...form.getFieldsValue(),
                      // building_info: DataHandle
                    },
                    null,
                    2
                  )}
                </pre>
              </Typography>
            )}
          </Form.Item>
        </div> */}

        {/* background */}
        <img
          alt=""
          className="w-screen h-screen object-cover flex"
          src={Background}
        />

        {/* dynamic form container */}
        <div className="min-w-[50%] max-w-3xl max-h-[calc(100vh-130px)] my-4 border-t-[8px] border-t-primary absolute top-10 right-8 bottom-0 bg-white mx-auto overflow-y-scroll">
          <div className="px-8 py-4">
            <div className="flex space-x-2 items-center">
              <div className="bg-primary w-[8px] h-8" />
              <div className="text-xl text-primary h-[30px] font-bold">
                Tell us about you
              </div>
            </div>

            <Form
              form={form}
              name="dynamic-form"
              autoComplete="off"
              onFinish={onFinish}
              scrollToFirstError={{
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
              }}
              // onFinishFailed={(values, errorFields, outOfDate) => {
              //   form.scrollToField("customer_name", { block: 'center', behavior: 'smooth' });
              //   console.log(
              //   `onFinishFailed: values: ${JSON.stringify(values)}, errorFields: ${errorFields}, outOfDate: ${outOfDate}`)}
              // }
            >
              <div className="mt-6 grid grid-cols-3 gap-x-6">
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: "this field is required!",
                    },
                  ]}
                >
                  <InputDefault title="Name" className="w-full" />
                </Form.Item>

                <Form.Item
                  className="col-span-2 w-full"
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "this field is required!",
                    },
                  ]}
                >
                  <InputDefault title="Email address" className="w-full" />
                </Form.Item>

                <Form.Item
                  name={"company"}
                  rules={[
                    {
                      required: true,
                      message: "this field is required!",
                    },
                  ]}
                >
                  <InputDefault title="Company" className="w-full" />
                </Form.Item>

                <Form.Item
                  name={"designation"}
                  rules={[
                    {
                      required: true,
                      message: "this field is required!",
                    },
                  ]}
                >
                  <InputDefault title="Designation" className="w-full" />
                </Form.Item>

                <Form.Item
                  name={"telephone"}
                  rules={[
                    {
                      required: true,
                      message: "this field is required!",
                    },
                  ]}
                >
                  <InputPhone 
                    title="Phone number"
                    className="w-full"
                  />
                  {/* <InputDefault title="Phone number" className="w-full" /> */}
                </Form.Item>
              </div>

              <div className="mb-4 mt-2 border-t-[1px] border-borderColor" />

              <div className="flex justify-end mb-4">
                <button
                  className=""
                  // onClick={() => setOpen(true)}
                  onClick={(e) => {
                    e.preventDefault()
                    window.open("https://www.kaer.com/services")
                  }}
                >
                  <img className="w-5 h-5" src={QuestionMark} alt=""></img>
                </button>
              </div>

              <div className="flex items-center justify-between space-x-8">
                <div className="flex flex-none space-x-2 items-center">
                  <div className="bg-primary w-[8px] h-8"></div>
                  <div className="text-xl text-primary h-[30px] font-bold">
                    What are you looking for
                  </div>
                </div>
                <ProductButton />
              </div>

              <div className="my-10 border-t-[1px] border-borderColor" />

              <div className="flex space-x-2 items-center mb-8">
                <div className="bg-primary w-[8px] h-8"></div>
                <div className="text-xl text-primary h-[30px] font-bold">
                  Tell us about your building
                </div>
              </div>
              <div>{RenderBuildingCallback()}</div>
            </Form>

            <div className="border-t-[1px] border-borderColor py-6 mt-6 flex justify-end items-center">
              <Button
                className="bg-primary text-white py-3 px-6 text-lg font-bold rounded-lg h-auto"
                onClick={() => {
                  form.submit()
                }}
                loading={loading}
              >
                Request for proposal
              </Button>
            </div>
          </div>
        </div>
        <div className="top-gray-background w-full flex justify-end items-end gap-4 absolute bottom-0 right-0 px-8">
          <a href="https://www.youtube.com/user/KaerAsia" target="blank"><img src={YoutubeIcon} className="max-h-14" /></a>
          <a href="https://www.linkedin.com/company/kaer-pte-ltd" target="blank"><img src={LinkedInIcon} className="max-h-14" /></a>
        </div>
        <div className="absolute bottom-0 left-0 ml-4 mb-4 text-white italic">version {VERSION_NO}</div>
      </div>

      <Modal
        title=""
        centered
        open={openSubmissionAlert}
        // onOk={() => setOpenSubmissionAlert(false)}
        // onCancel={() => setOpenSubmissionAlert(false)}
        footer={null}
        closable={false}
        width={1000}
      >
        <div className="done-modal">
          <img src={DoneIcon} />
          <p className="done-modal-msg">Awesome</p>
          <div className="done-modal-reference-no">
            Reference No. : {submissionID.split("-")[0].toUpperCase()}
          </div>
          <div className="done-modal-description">
            <p>We have everything we need and a member of the Kaer Team will follow up with you within the next 2 days.</p>
            <p>You will also receive a mail in your inbox with a summary of your submission.</p>
          </div>
          <div className="done-modal-buttons">
            <button className="done-modal-button-blue" onClick={handleBackToHomeClicked}>Back to Home</button>
            <button className="done-modal-button-blue" onClick={handleSubmitAnotherClicked}>Submit Another</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={open}
        title=""
        onCancel={handleCancel}
        footer={[]}
        width={"80%"}
      >
        <div className="p-6">
          <img className="w-full" src={Scheme} alt="scheme" />
          <ul className="list-disc list-inside">
            <li className="bg-[#005DAA] text-white text-lg font-semibold px-4">
              Kaer Water=Chilled water supply temperature
            </li>
            <li className="bg-[#2196F3] text-white text-lg font-semibold px-4">
              Kaer Air=Space conditions
            </li>
          </ul>
        </div>
      </Modal>
    </ConfigProvider>
  )
}

export default App
